import * as React from "react"
import { Link } from "@StarberryUtils";

import Seo from "../components/seo"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"
import NotFound from "@components/404"

const NotFoundPage = () => (
  <>
    <NotFound />
  </>
)

export default NotFoundPage
