import * as React from "react"
import { Link } from "@StarberryUtils";

import Seo from "../components/seo"
import Header from "../components/Header/Header"
import Footer from "../components/Footer/Footer"

const NotFoundPage = () => (
  <>
    <Header />
    <Seo title="404: Not found" />
    <div className="error-page">
    <h1>Ooops!...</h1>
    <h4>The page you’re after seems to be missing.</h4>
    <p>This is maybe because it has been removed, we’ve changed it’s name or it is temporarily unavailable.</p>
    <Link to="/contact/" className="btn">Contact Us</Link>
    </div>
    <Footer />
  </>
)

export default NotFoundPage
